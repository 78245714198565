export const environment = {
  protocol: 'https',
  port: null,
  host: 'api.prodamti.cz',
  basicAuthToken: '',
  debug: false,
  appUrl: 'ProdamTi.cz',
  metaUrl: 'https://prodamti.cz',
  topLevelDomain: 'cz',
  defaultLang: 'cs',
  defaultCurrency: 'CZK',
  eurCzkRate: 25,
  facebookPixelCode: '379530170820768',
  googleAnalyticsConfigId: 'G-DYK4YBL4LC',
  smartEmailingGuid: 'a93fa934-e114-11ec-9737-3cecef38fa8d',
  awaitStripeWebhookInterval: 3500,
  landingPageHowItWorksUrl: 'https://byznys.prodamti.cz',
  landingPageFindOutMoreAutomoto: 'https://byznys.prodamti.cz',
  user: {
    roles: {
      user: 'user',
      admin: 'admin',
      companyOwner: 'company-owner'
    }
  },
  offer: {
    status: {
      draft: 'draft',
      published: 'published',
      closed: 'closed',
      banned: 'banned',
      denied: 'denied',
      deactivated: 'deactivated',
      paused: 'paused',
    },
    mainCategory: {
      automoto: 'automoto',
      spareParts: 'spare-parts',
      marketplace: 'marketplace',
      properties: 'nehnutelnosti'
    },
    automotoCategories : {
      personalVehicles: 'osobne-vozidla',
      motorcycles: 'motocykle',
      over35t: 'vozidla-od-35t-do-75t',
      over75t: 'vozidla-nad-75t',
      truck: 'tahace-navesov',
      buses: 'autobusy',
      trailer: 'privesy',
      semiTrailer: 'navesy',
      forkLiftTrucks: 'vysokozdvizne-voziky',
      carTrailer: 'privesne-voziky',
      constrMachinery: 'stavebne-stroje',
      agroMachinery: 'polnohospodarske-stroje',
      caravans: 'karavany'
    },
    marketplaceCategories: {
      electronics: 'elektronika',
      kids: 'deti',
      cosmetics: 'kozmetika',
      music: 'hudba',
      houseAndGarden: 'dom-a-zahrada',
      furniture: 'nabytok',
      sportAndTourism: 'sport-a-turistika',
      petAccessories: 'chovatelske-potreby',
      tickets: 'listky-a-vstupenky'
    },
    marketType: {
      buyNow: 'buy-now',
      freeOffer: 'free-offer',
      auction: 'auction'
    },
    imageUploadSettings: {
      maxSizeMB: 10,
      maxWidthOrHeight: 1920
    },
    carCharacteristics: {
      airbags: 'airbags',
      airbagsCount: "airbag_number",
      color: 'vehicle_color',
      vehicleType: 'vehicle_type',
      drive: 'drive',
      transmission: 'transmission',
      doors: 'doors',
      seats: 'seats',
      emissions: 'emissions',
      batteryCapacity: 'battery_capacity',
      batteryRent: 'battery_rent',
      additionalInfo: 'additional_information',
      equipment: 'Equipment',
      priceOptions: 'price_options',
      safety: 'safety_comform_eq',
      motorcyclesEquipment: 'motorcycles_eq',
      caravanEquipment: 'caravan_eq',
      busEquipment: 'bus_eq',
      busSeats: 'bus_seats',
      busSeatsProps: 'bus_seats_properties',
      busLuggage: 'bus_luggage_compartment',
      busStructure: 'bus_superstructure',
      carTrailerMaxWeight: 'trailer_max_weight',
      caravanMaxWeight: 'max_caravan_weight',
      caravanBedsCount: 'caravan_beds',
      caravanBathrooms: 'caravan_bathrooms',
      caravanWcs: 'caravan_wcs',
      sections: {
        safety: 'safety',
        comfort: 'comfort',
        other: 'other_eq'
      }
    },
    fuels: {
      petrol: 'Benzín',
      diesel: 'Diesel',
      LPG: 'LPG + Benzín',
      electric: 'Elektromotor',
      dieselElectric: 'Hybrid diesel+el.',
      petrolElectric: 'Hybrid benzin+el.',
      CNG: 'CNG',
      bioethanol: 'Bioetanol'
    },
    inappropriateFlagsTypes: {
      photos: 'photos',
      title: 'title',
      detailedDescription : 'detailedDescription',
      other: 'other'
    }
  },
  refund: {
    status: {
      requested: 'REQUESTED',
      rejected: 'REJECTED',
      partiallyAccepted: 'PARTIALLY_ACCEPTED',
      accepted: 'ACCEPTED'
    }
  },
  order: {
    status: {
      received: 'RECEIVED',
      processing: 'PROCESSING',
      shipped: 'SHIPPED',
      delivered: 'DELIVERED',
      refunded: 'REFUNDED',
      canceled: 'CANCELED',
      partiallyRefunded: 'PARTIALLY_REFUNDED'
    }
  },
  profile: {
    changeToBusinessAccount: '',
    deliveryMethodSettingsInfo: '',
    conditionsOfUse: '',
    privacyNotice: ''
  },
  stripe: {
    publishableKeySK: "pk_live_51Hsy2jEpFCUrM7bAareoh8SUUUCck0pUk9l6jAqC40SRbhuo7Gsm5yKAkTqmZxlHPBCHKaD9y2KQPCLSZezCgF8600Ngut9GIG",
    publishableKeyCZ: "pk_live_51I7LE5Ag1mAYTvim43BQBOTi5oZjxpSnujgY6BPZKc66TmFJCS9fvK9kJXJLPwi5cqdAz2egfkytliUW7zpeKYv800EcymCjQn"
  } as Record<string, string>,
  RECAPTCHA_V3_SCORE_SITE_KEY: '6Lernr8bAAAAAG4Q2M51SEZdjDV_vT3goJAPilDL',
  RECAPTCHA_V2_SCORE_SITE_KEY: '6Ld5n78bAAAAALfHlM_EHf4txfG7aebPsVsJ6eSa',
  transaction: {
    type: {
      card: {
        orderPaid: 'orderPaid',
        sellerOrderPaid: 'sellerOrderPaid',
        orderPaidOut: 'orderPaidOut',
        orderPayOutSellerCharged: 'orderPayOutSellerCharged',
        orderCommissionPaid: 'orderCommissionPaid',
        creditPurchase: 'creditPurchase',
        topOptionPurchase: 'topOptionPurchase',
        publishOptionPurchase: 'publishOptionPurchase',
        orderCancelled: 'orderCancelled',
        sellerOrderCancelled: 'sellerOrderCancelled',
      },
      credit: {
        purchase: 'PURCHASE',
        usageOfferTop: 'USAGE_OFFER_TOP',
        usageOfferPublish: 'USAGE_OFFER_PUBLISH',
        earnedForRating: 'EARNED_FOR_RATING',
        earnedForOrder: 'EARNED_FOR_ORDER',
        earnedForCreditPurchase: 'EARNED_FOR_CREDIT_PURCHASE',
      }
    },
    status: {
      success: 'SUCCESS',
      failed: 'FAILED',
      requested: 'REQUESTED',
      canceled: 'CANCELED'
    }
  },
  credit: {
    minimumCountToPurchase: {
      eur: 500,
      czk: 15000,
    }
  },
  returnTOC: {
    contractWithdrawal: {
      allowed: 'ALLOWED',
      limitedOrNotAllowed: 'LIMITED_OR_NOT_ALLOWED',
    },
    returnShippingPayer: {
      buyer: 'BUYER',
      seller: 'SELLER',
    },
    minReturnTOCDays: 14
  },
  returnPolicy: {
    validity: {
      oneYear: 'oneYear',
      twoYears: 'twoYears',
      moreThanTwoYears: 'moreThanTwoYears',
      noWarranty: 'noWarranty'
    }
  },
  enum: {
    type: {
      singleChoice: 'SINGLE_CHOICE',
      multiChoice: 'MULTI_CHOICE',
    }
  },
  googlePrivacyUrl: 'https://policies.google.com/privacy',
  googleTermsUrl: 'https://policies.google.com/terms',
  rating: {
    offerRatingProperty: {
      type: {
        positive: 'POSITIVE',
        negative: 'NEGATIVE'
      }
    }
  },
  creditsEarnedForOrder: {
    forEachCZK: 30, // positive number
    earnCZK: 0.3,
    forEachEUR: 1,  // positive number
    earnEUR: 0.01
  },
  conversation: {
    maximumOrderConversationCreationTime: "90 days"
  },
  rollbar: {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    version: process.env.REACT_APP_ROLLBAR_CODE_VERSION,
    environment: 'production-cz',
  },
  carVertical: {
    affiliateId: 'vshkns007', // a=
    campaignId: '77dfdc26', // b=
    voucherName: 'ProdamTi10', //voucher=
    baseUrl: 'https://www.carvertical.com',
    precheckUrlPath: 'precheck',
    discountPercentage: '10',
  }
};
